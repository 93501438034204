
import * as cbor from 'cbor';
import Fuse from 'fuse.js';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig  from '../tailwind.config.js';

export default () => ({

  screenSizes: Object.fromEntries( 
          Object.entries(resolveConfig(tailwindConfig).theme.screens)
                .map( ([k, v]) => [k , parseInt(v)] ) 
  ),
  get activeScreenSizes() {  // based on tailwind screen breaks
    return Object.keys(this.screenSizes).filter((k) => this.screenSizes[k] >= window.innerWidth)
  },

  pagetheme: 'simple',

  // all of this just to format some dates!!
  days : [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
  months : [ 'Jan.', 'Feb.', 'March', 'April', 'May', 'June', 'July', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.' ],
  getLocalDate(utcdate) {
    return new Date(utcdate.getTime() + (utcdate.getTimezoneOffset() * 60000) + 1000)
  },
  formatDate(d) {
    let year = d.getFullYear()
    let day = d.getDate()
    let month = this.months[d.getMonth()]
    let weekday = this.days[d.getDay()]
    return `${weekday}, ${month} ${day}, ${year}`
  },
  // dateWithoutYear(dateString) {
  //   return dateString.substring(0, dateString.indexOf(","))
  // },
  jobDate(d) {
    return this.activeScreenSizes.includes('sm') ? 'sss' : 'llllllllll'
    // this.formatDate(d).substring(0, dateString.indexOf(",")) : this.formatDate(d)
  },

  // core static variables
  joblist : [],
  joblistFiltered : [],
  updatedDate : new Date('1999-12-31'),
  currentlyLoading : false,
  dontLoadAll : new URLSearchParams(window.location.search).has('slow'),


  // Fuse object is set in the init phase (because it relies on some variables that haven't been initiated yet)
  fuse: null,
  fuseOptions: {
    keys: ["jobTitle", "city", "state"],
    location: 0,
    distance: 100,
    threshold: 0.30,
    // useExtendedSearch: true
  },
  
  // these are bound to the UI via Alpine
  // `x-model` 
  searches: {
    jobTitle: "",
    city: "",
    state: ""
  },
  // `x-on:click`
  sortAscending: {
    jobTitle: true,
    location: false,
    postedOnDate: true
  },
  sortArrows: [null, null, null],
  get upArrows() { return this.sortArrows.map((a) => a == 'up') },
  get downArrows() { return this.sortArrows.map((a) => a == 'down') },


  openJob(hash) {
    window.open(`https://cjl.st/job/${hash}`, '_blank')
  },
  fuseSearch(searchterms) {
    let searchentries = Object.entries(searchterms)
                          .filter(([k, v]) => v.length > 1 && k.length >= 0)
                          .map(([k, v]) => ({ [k] : v }))
    if (searchentries.length == 0) {
      this.joblistFiltered = this.joblist
    } else {
      console.log(`search initiated: [${Object.entries(searchterms).map(([k, v]) => v).join(', ')}]`)
      this.joblistFiltered = this.fuse.search({$and: searchentries}).map( j => j.item )
      this.sortAscending.postedOnDate = false
      this.sortArrows = [null, null, null]
    }
  },

  async loadJobListCBOR() {
    const fetchUrl = this.dontLoadAll ? 'https://data.casinojoblist.com/jobs3day.data' : 'https://data.casinojoblist.com/jobs.data'
    console.log('about to fetch job data')
    await fetch(fetchUrl, { })
      .then(res => res.arrayBuffer()).then((response) => {
        console.log('fetch completed')
        let obj = cbor.decode(response)
        obj.jobs.forEach(job => { 
          job.postedOnDate = Date.parse(job.postedOn);
        })
        // this.joblist = this.sortJobs('postedOnDate', obj.jobs)
        this.sortJobs('postedOnDate', obj.jobs)
        this.sortArrows = ['up', null, null]
        let rawDate = new Date(obj.updated)
        this.updatedDate = this.getLocalDate(rawDate)
        console.log(`initial load complete with ${this.joblist.length} jobs`)
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
  },

  async setupJobs() {
    console.log('page loaded, data import process starting')
    this.currentlyLoading = true
    await this.loadJobListCBOR()
    this.currentlyLoading = false
    console.log('importing complete')
    console.log('%c  [[[ Need to speed up your development process? ]]]', 'color: teal; font-weight: bold') 
    console.log('%c  [[[ Hoping to update your tech stack? ]]]', 'color: steelblue; font-weight: bold') 
    console.log('%c  [[[ Getting frustrated trying to connect to external APIs? ]]]', 'color: tomato; font-weight: bold')
    console.log('%c  "Centerfield Nine" (a.k.a. Andrew Goldberg) can help!  ', 'background-color: #2BC0E4; color: white; font-weight: bold')
    console.log(`%c  cfnin${'e.c'}om or 1-8${'00'}-CF9-${'998'}9  `, 'background-color: #2BC0E4; color: white; font-weight: bold')
    this.sortAscending.postedOnDate = true
    this.sortArrows = ['up', null, null]

    let fuseindex = Fuse.createIndex(this.fuseOptions.keys, this.joblist)
    this.fuse = new Fuse(this.joblist, this.fuseOptions)
    this.joblistFiltered = this.joblist.slice(0, 100000)

    console.log(`setup complete with ${this.joblist.length} jobs`)
  }, 

  sortJobs(column, existingArray = null) {
    // existingArray is used when we have the result of the http fetch but joblist is not yet populated
    let newArray = existingArray || this.joblistFiltered
    this.sortAscending[column] = !this.sortAscending[column]
    // always sort by job title first
    console.log(this.sortAscending['jobTitle'])
    newArray = (this.sortAscending['jobTitle']) ? 
                  newArray.sort((a,b) => a.jobTitle.localeCompare(b.jobTitle)) : 
                  newArray.sort((a,b) => b.jobTitle.localeCompare(a.jobTitle))

    if (column == 'postedOnDate') {
      // numeric comparison
      newArray = (this.sortAscending[column]) ? 
                    newArray.sort((a,b) => a[column] - b[column]) : 
                    newArray.sort((a,b) => b[column] - a[column])
      this.sortArrows = [this.sortAscending[column] ? 'down' : 'up', null, null]
    } else if (column == 'location') {
      // string comparison
      newArray = (this.sortAscending[column]) ? 
                    newArray.sort((a,b) => a[column].localeCompare(b[column])) : 
                    newArray.sort((a,b) => b[column].localeCompare(a[column]))
      this.sortArrows = [null, this.sortAscending[column] ? 'down' : 'up', null]
    } else {
      this.sortArrows = [null, null, this.sortAscending[column] ? 'down' : 'up']
    }


    if (existingArray) {
      // only on first call (with data from fetch)
      // after this, the base joblist never changes, which is required for fusejs search to work correctly
      this.joblist = newArray
    } else {
      this.joblistFiltered = newArray
    }
  },

})


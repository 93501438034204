"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asCustomProp = exports.toCustomPropName = exports.toCustomPropValue = exports.escape = void 0;
const colorUtils_1 = require("./colorUtils");
/**
 * Code copied from the tailwind official codebase
 * @link https://github.com/tailwindlabs/tailwindcss/blob/fbbba6f67f73c3a4f9571649c3fc27006446d8f4/src/lib/regex.js#L70
 */
const REGEX_SPECIAL = /[\\^$.*+?()[\]{}|]/g;
const REGEX_HAS_SPECIAL = RegExp(REGEX_SPECIAL.source);
const escape = (string) => {
    return string && REGEX_HAS_SPECIAL.test(string)
        ? string.replace(REGEX_SPECIAL, '\\$&')
        : string || '';
};
exports.escape = escape;
/**
 * @param value - a custom prop value
 * @return the value converted to a string of its rgb components comma separated if it is a color else it returns the value unaltered
 */
const toCustomPropValue = (value) => {
    if (typeof value === 'number') {
        return value.toString();
    }
    else if ((0, colorUtils_1.isColor)(value)) {
        return (0, colorUtils_1.toRgb)(value);
    }
    else {
        return value;
    }
};
exports.toCustomPropValue = toCustomPropValue;
const whitespaceRegex = /\s/g;
/**
 * @param valuePath - the path to get to the value
 * @return valuePath concatenated as a kebab cased custom property
 */
const toCustomPropName = (valuePath) => {
    if (valuePath.some(x => whitespaceRegex.test(x))) {
        throw new Error(`cannot have whitespace in any property in a theme config, found "${valuePath.find(x => whitespaceRegex.test(x))}"`);
    }
    return (0, exports.escape)(`--${valuePath.filter(step => step.toLowerCase() !== 'default').join('-')}`);
};
exports.toCustomPropName = toCustomPropName;
/**
 * @param value - the value of the custom prop to generate
 * @param valuePath - the path to get to the value
 * @return a normal custom prop generated from valuePath if the value is not a color else it is a function that generates custom prop configured with opacity when called with opacity configuration
 */
const asCustomProp = (value, valuePath) => {
    const customPropName = (0, exports.toCustomPropName)(valuePath);
    if ((0, colorUtils_1.isColor)(value)) {
        return (0, colorUtils_1.withOpacity)(customPropName);
    }
    else {
        return `var(${customPropName})`;
    }
};
exports.asCustomProp = asCustomProp;

/** @type {import('tailwindcss').Config} */
const colors = require("tailwindcss/colors")
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: ["./src/**/*.{html,js}"],
  theme: {
    extend: {

      width: {
        '1/10': '10%',
        '2/10': '20%',
        '3/10': '30%',
        '4/10': '40%',
        '5/10': '50%',
        '6/10': '60%',
        '7/10': '70%',
        '8/10': '80%',
        '9/10': '90%',
        '1/8': '12.5%',
        '2/8': '25%',
        '3/8': '37.5%',
        '4/8': '50%',
        '5/8': '62.5%',
        '6/8': '75%',
        '7/8': '87.5%',
        '15p' : '15%',
        '25p' : '25%',
        '60p' : '60%'                 
      },
      minWidth: {
        '1/10': '10%',
        '2/10': '20%',
        '3/10': '30%',
        '4/10': '40%',
        '5/10': '50%',
        '6/10': '60%',
        '7/10': '70%',
        '8/10': '80%',
        '9/10': '90%',
        '1/8': '12.5%',
        '2/8': '25%',
        '3/8': '37.5%',
        '4/8': '50%',
        '5/8': '62.5%',
        '6/8': '75%',
        '7/8': '87.5%', 
        '15p' : '15%',
        '25p' : '25%',
        '60p' : '60%'                     
      },
      maxWidth: {
        '1/10': '10%',
        '2/10': '20%',
        '3/10': '30%',
        '4/10': '40%',
        '5/10': '50%',
        '6/10': '60%',
        '7/10': '70%',
        '8/10': '80%',
        '9/10': '90%',
        '1/8': '12.5%',
        '2/8': '25%',
        '3/8': '37.5%',
        '4/8': '50%',
        '5/8': '62.5%',
        '6/8': '75%',
        '7/8': '87.5%', 
        '15p' : '15%',
        '25p' : '25%',
        '60p' : '60%'                     
      },
    }
  },
  plugins: [
    require('tailwindcss-themer') ({
      defaultTheme: {
        extend: {
          fontFamily: {
            sans: ['Karla', 'Barlow', 'ui-sans-serif', 'system-ui'],
            titlefont: ['Barlow'],
            karla: ['Karla']
          },
          lineHeight: {
            jobtable: defaultTheme.lineHeight.snug
          },
          // lineHeight: ({ theme }) => ({
          //   jobtable: 'leading-tight',
          // }),
          // fontSize: {
          //   // sm: '0.9rem',
          //   // lg: ['0.8rem', { lineHeight : '2rem' } ],
          //   // lg: '0.8rem' //, '2rem' ],
          //   // lg: defaultTheme.fontSize.lg,
          //   // '5xl': defaultTheme.fontSize['5xl']
          // },
          fontSize: {
            sm: defaultTheme.fontSize.sm,
            // normal: defaultTheme.fontSize.normal,
            lg: defaultTheme.fontSize.lg
          },
          colors: {
            pagebackground: colors.sky["100"],
            maintext: colors.slate["900"],
            jobhover: colors.yellow["300"],
            jobhovertext: colors.slate["900"],
            themer: colors.zinc["s00"],
            themetext: colors.rose["800"],
            searchbox: colors.yellow["200"],
            searchtext: colors.slate["950"]
          }
        }
      },
      themes: [
        { name: 'terminal',
          extend: {
            fontFamily: {
              sans: ['FlexiIBMVGA', 'ui-sans-serif', 'system-ui'],
              titlefont: ['FlexiIBMVGA']
            },
            fontSize: {
              sm: defaultTheme.fontSize.sm,  //'0.8rem',
              lg: defaultTheme.fontSize.lg   //'0.9rem'
            },
            colors: {
              pagebackground: colors.gray["950"],
              maintext: colors.green["400"],
              jobhover: colors.green["400"],
              jobhovertext: colors.gray["950"],
              searchbox: colors.gray["950"],
              searchtext: colors.green["400"]
            }
          }
        },
        { name: 'slotglass',
          extend: {
            fontFamily: {
              sans: ['MontereyBold', 'ui-sans-serif', 'system-ui'],
              titlefont: ['MontereyBold']
            },
            fontSize: {
              sm: '0.9rem',
              lg: '1.15rem'
            },            
            lineHeight: {
              jobtable: defaultTheme.lineHeight.normal
            },
            colors: {
              pagebackground: colors.emerald["50"],
              maintext: colors.purple["500"],
              jobhover: colors.emerald["600"],
              jobhovertext: colors.purple["200"],
              searchbox: colors.emerald["200"],
              searchtext: colors.purple["700"]
            }
          }
        },
        { name: 'neon',
          extend: {
            fontFamily: {
              sans: ['Beon', 'ui-sans-serif', 'system-ui'],
              titlefont: ['Beon']
            },
            lineHeight: {
              jobtable: defaultTheme.lineHeight.normal
            },            
            colors: {
              pagebackground: colors.indigo["900"],
              maintext: '#FF00FF',
              jobhover: colors.gray["950"],
              jobhovertext: '#00FFFF', // colors.purple["200"],
              searchbox: colors.purple["300"],
              searchtext: colors.purple["900"]
            },
          }
        },
        { name: 'sportsbook',
          extend: {
            fontFamily: {
              sans: ['FridayNightLights', 'ui-sans-serif', 'system-ui'],
              titlefont: ['FridayNightLights']
            },
            // fontSize: {
            //   // sm: '0.9rem',
            //   // lg: ['0.8rem', { lineHeight : '2rem' } ],
            //   // lg: '0.8rem' //, '2rem' ],
            //   lg: '.95rem',
            //   // '5xl': '2.25rem'
            // },
            // lineHeight: {
            //   jobtable: defaultTheme.lineHeight.normal 
            // },            
            colors: {
              pagebackground: colors.gray["700"],
              maintext: colors.amber["400"],
              jobhover: colors.zinc["950"],
              // jobhovertext: colors.red["700"],
              searchbox: colors.gray["400"],
              searchtext: colors.green["800"]
            },
          }
        },                
      ]
    })
  ],
}

